/* global JSZip */
angular.module('LeasePilot').directive('abstractModal', [
  '$rootScope',
  '$mdDialog',
  'ApiService',
  'LeaseEditorService',
  function(
    $rootScope,
    $mdDialog,
    ApiService,
    LeaseEditorService,
  ) {
    return {
      restrict: 'E',
      templateUrl: 'abstract_modal.html',
      scope: {
        download: '=',
      },
      link: function($scope, element, attrs) {
        $scope.documentName = `${scopeVar.title.tenant} - ${scopeVar.title.building}`;

        if (scopeVar.user.company.companyProfile.hasIterations) {
          $scope.documentVersion = `${scopeVar.title.version.major} - ${scopeVar.title.version.minor}`;
        } else {
          $scope.documentVersion = `${scopeVar.title.version.major}`;
        }

        $scope.processing = false;
        $scope.showMask = false;
        $scope.abstractType = 'current';
        $scope.wordDoc = null;
        $scope.errors = { wordDoc: 'No file selected' };
        $scope.downloadErrorMessage =
          'Oops... something went wrong with the download process, please wait a couple of seconds and try again.';

        $scope.cancel = function() {
          $mdDialog.cancel();
        };

        $scope.uploadFile = function(file) {
          $scope.wordDoc = file;
        };

        $scope.abstract = async function() {
          let doc = null;

          $scope.safeApply(() => {
            $scope.processing = true;
          });

          if ($scope.abstractType === "current") {
            doc = document.querySelector(".document").innerText;
          } else if ($scope.abstractType === "upload") {
            const result = await ApiService.documentToHTML($scope.wordDoc);

            if (result.status === 200) {
              const html = document.createElement("html");
              html.innerHTML = result.data;
              doc = html.querySelector("body").innerText;
            } else {
              // 
            }
          }

          doc = doc
            .replace(/\u200B/g, "") // ZERO WIDTH SPACE
            .replace(/\u200D/g, "") // ZERO WIDTH JOINER
            .replace(/\ufeff/g, ""); // ZERO WIDTH NO-BREAK SPACE;

          scopeVar.downloadAbstract(doc);

          $mdDialog.cancel();
        }
      },
    };
  },
]);
